<template>
  <div class="limitData">
    <div class="cell">
      <div class="title">
        商店的限时选购数据详情
        <span
          @click="handleEdit('info')"
          style="
            font-size: 14px;
            margin-left: 20px;
            cursor: pointer;
            color: #2688e6;
          "
          >查看商店的限时选购详情
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div
        style="
          background: #fafafa;
          padding: 10px;
          margin-top: 30px;
          font-size: 14px;

          display: flex;
          align-items: center;
        "
      >
        <div style="margin-right: 20px">
          状态：<span class="tag1" v-if="infoData.status_txt == '进行中的活动'"
            >进行中的活动</span
          >
          <span class="tag" v-if="infoData.status_txt == '接下来的活动'"
            >接下来的活动</span
          >
          <span class="tag2" v-if="infoData.status_txt == '已过期'"
            >已过期</span
          >
        </div>
        <div>活动时间：{{ infoData.deal_time }}</div>
      </div>
      <div class="list">
        <div class="list-head">
          <div style="width: 5%; text-align: center">排名</div>
          <div style="width: 20%">商品</div>
          <div style="width: 20%">规格</div>
          <div style="width: 10%">活动库存</div>
          <div style="width: 10%">折扣价格</div>
          <div style="width: 10%; display: flex; align-items: center">
            <span style="margin-right: 6px"> 销售额</span>
            <div>
              <div
                :class="
                  field == 'actual_payment' && order == 1
                    ? 'triangleC'
                    : 'triangleT'
                "
                @click="handleSort('actual_payment', 1)"
              ></div>
              <div
                :class="
                  field == 'actual_payment' && order == 2
                    ? 'triangleD'
                    : 'triangleB'
                "
                @click="handleSort('actual_payment', 2)"
              ></div>
            </div>
          </div>
          <!-- <div style="width: 10%; display: flex; align-items: center">
            <span style="margin-right: 6px"> 订单数</span>
            <div>
              <div class="triangleT"></div>
              <div class="triangleB"></div>
            </div>
          </div> -->
          <div style="width: 10%; display: flex; align-items: center">
            <span style="margin-right: 6px"> 件数</span>
            <div>
              <div
                :class="
                  field == 'number' && order == 1 ? 'triangleC' : 'triangleT'
                "
                @click="handleSort('number', 1)"
              ></div>
              <div
                :class="
                  field == 'number' && order == 2 ? 'triangleD' : 'triangleB'
                "
                @click="handleSort('number', 2)"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="listData.list && listData.list.length != 0">
          <div
            class="list-content"
            v-for="(item, index) in listData.list"
            :key="item.id"
          >
            <div style="width: 5%; text-align: center">{{ index + 1 }}</div>
            <div style="width: 20%; display: flex">
              <div>
                <el-image
                  style="width: 50px; height: 50px; margin-right: 10px"
                  :src="item.image"
                  fit="fit"
                ></el-image>
              </div>
              <div>{{ item.title }}</div>
            </div>
            <div style="width: 20%">{{ item.difference }}</div>
            <div style="width: 10%">{{ item.active_stock }}</div>
            <div style="width: 10%">RM{{ item.discount_price }}</div>
            <div style="width: 10%">RM{{ item.actual_payment }}</div>
            <!-- <div style="width: 10%">{{ item.actual_payment }}</div> -->
            <div style="width: 10%">{{ item.number }}</div>
          </div>
        </div>
        <div
          v-else
          style="text-align: center; color: #b0b0b0; line-height: 100px"
        >
          暂无
        </div>
      </div>
      <div style="display: flex; justify-content: right; padding-top: 20px">
        <el-pagination
          :page-size="page_size"
          :current-page="page"
          background
          layout="prev, pager, next"
          :total="listData.total"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getLimitPurchaseInfo,
  limitPurchaseOrderList,
} from "@/api/marketing.js";
export default {
  data() {
    return {
      infoData: "",
      listData: {
        list: [],
      },
      page: 1,
      page_size: 10,
      field: "number",
      order: 0,
    };
  },
  created() {
    this.getData();
    this.getList();
  },
  methods: {
    getData() {
      getLimitPurchaseInfo({
        limit_purchase_id: sessionStorage.LIMITID,
      }).then((res) => {
        if (res.code == 1) {
          this.infoData = res.data;
        }
      });
    },
    getList() {
      limitPurchaseOrderList({
        limit_purchase_id: sessionStorage.LIMITID,
        field: this.field,
        order: this.order,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
        }
      });
    },
    pageChange(index) {
      this.page = index;
      this.getList();
    },
    handleEdit(type) {
      sessionStorage.LIMITTYPE = type;
   
      this.$router.options.routes.forEach((val) => {
        if (val.name == "marketingCenters") {
       
          val.children.forEach((item) => {
            if (item.name == "createLimit") {
              item.meta.bread[3].name = "限时选购详情";
            }
          });
        }
      });
      this.$router.push("createLimit");
    },
    // 排序
    handleSort(type, val) {
      this.field = type;
      this.order = val;
      this.$forceUpdate();
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.limitData {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 40px;
  .cell {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    .title {
      font-size: 20px;
    }
    .tag1 {
      background: #eaf9ef;
      color: #55cc77;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
    }
    .tag {
      background: #fff1f0;
      color: #ee4d2d;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
    }
    .tag2 {
      background: #eeeeee;
      color: #666;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
    }

    .list {
      border: 1px solid #eee;
      margin-top: 20px;
      border-radius: 2px;
      font-size: 14px;
      padding-bottom: 30px;
      .list-head {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f6f6f6;
      }
      .list-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        margin: 0 20px;
        border-bottom: 1px solid #eee;
      }
    }
  }
}
</style>